import * as React from 'react'
import Layout from '../@lekoarts/gatsby-theme-cara/components/layout'
import HeroAboutUs from '../@lekoarts/gatsby-theme-cara/components/AboutUs/heroAboutUs'
import Contact from '../@lekoarts/gatsby-theme-cara/components/AboutUs/contact'
import { Box } from 'theme-ui'
import Footer from '../@lekoarts/gatsby-theme-cara/components/footer'
import { History } from '../@lekoarts/gatsby-theme-cara/components/AboutUs/history'

const AboutUs = () => (
  <Layout>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden scroll',
        transform: 'translate3d(0px, 0px, 0px)',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: '350vh',
          transform: 'translate3d(0px, 0px, 0px)',
        }}
      >
        <HeroAboutUs height="100dvh" />
        <History top="150vh" height="100vh" />
        <Contact top="250vh" height="100vh" />
        <Footer />
      </Box>
    </Box>
  </Layout>
)

export default AboutUs
